.textField {
    width: 100%;
    margin: 0 0 0.4rem 0 !important;
}

.redColor {
    margin: 0 0;
    color: rgb(255, 100, 100) !important;
    font-size: 14px;
    text-align: left !important;
}

.imagen{
    background: linear-gradient(0deg, rgba(27, 27, 27, 0.4), rgba(27, 27, 27, 0.4)),url('../../assets/body/lifetime_header.jpg');
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
}

.custom-input-file {
    background-color: #d1d1d1;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    margin: 0 auto 0;
    min-height: 15px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    text-align: center;
}

.custom-input-file .input-file {
    border: 10000px solid transparent;
    cursor: pointer;
    font-size: 10000px;
    margin: 0;
    opacity: 0;
    outline: 0 none;
    padding: 0;
    position: absolute;
}