.textField {
    width: 100%;
    margin: 0 0 0.4rem 0 !important;
}

.redColor {
    margin: 0 0;
    color: rgb(255, 100, 100) !important;
    font-size: 14px;
    text-align: left !important;
}

.imagen {
    background: linear-gradient(0deg, rgba(27, 27, 27, 0.4), rgba(27, 27, 27, 0.4)), url('../../assets/body/lifetime_header.jpg');
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
}

.MuiButtonBase-root:disabled {
    background-color: #e6e6e6;
}